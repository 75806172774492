/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* Container Styling */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f9;
}

/* Login Box */
.login-box {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 350px;
}

/* Logo Placeholder */
.logo-placeholder {
  margin-bottom: 20px;
}

.logo-placeholder img {
  width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Title Styling */
.login-box h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
  font-family: 'Arial', sans-serif;
}

/* Form Group Styling */
.form-group {
  margin-bottom: 20px;
  text-align: left;
}

/* Input Field Styling */
.input-field {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Buttons */
.request-button, .submit-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.request-button:hover, .submit-button:hover {
  background-color: #0056b3;
}

/* Error Message */
.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}
