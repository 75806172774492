/* Dashboard.css */

* {
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f6f8;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    padding: 80px 20px 80px; /* Add bottom padding to accommodate the fixed footer */
    min-height: 100vh;
    background-color: #f4f6f8;
    position: relative; /* Relative positioning for the floating button */
}

.schedules-list {
    flex-grow: 1; /* Allow schedules-list to take available space */
    display: flex; /* Enable flexbox for centering */
    justify-content: center; /* Center content horizontally */
    margin-bottom: 80px; /* Ensure there's space for the footer */
}

.pagination-container {
    width: 100%;
    max-width: 600px; /* Max width for pagination */
    margin-top: 30px;
}

.add-schedule-btn {
    position: fixed; /* Fixed position for floating button */
    right: 20px; /* Distance from the right edge */
    bottom: 100px; /* Adjusted distance from the bottom edge to ensure visibility above the footer */
    background-color: #007bff; /* Button color */
    color: white;
    padding: 15px;
    border: none;
    border-radius: 50%; /* Circular button */
    cursor: pointer;
    font-size: 24px; /* Increase font size for visibility */
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2);
}

.add-schedule-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.footer {
    position: fixed; /* Fixed position for footer */
    bottom: 0; /* Align at the bottom of the page */
    left: 0; /* Align at the left */
    right: 0; /* Align at the right */
    background-color: #fff; /* Footer background color */
    padding: 10px 20px; /* Footer padding */
    text-align: center; /* Center text in footer */
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow */
}

@media (max-width: 768px) {
    .add-schedule-btn {
        padding: 10px;
    }
}
/* Add this CSS to your existing Dashboard.css */

.schedules-list {
    flex-grow: 1; /* Allow schedules-list to take available space */
    display: flex; /* Enable flexbox for centering */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    margin-bottom: 80px; /* Ensure there's space for the footer */
}

.no-schedules {
    font-size: 18px; /* Font size for the message */
    color: #ff4d4d; /* Color for the message */
    text-align: center; /* Center the text */
    padding: 20px; /* Add some padding */
}

.watch-tut {
    font-size: 18px; /* Font size for the message */
    text-align: center;
    padding: 20px;
}
