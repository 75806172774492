/* Header.css */
.header {
    display: flex;
    justify-content: space-between; /* Space between logo, title, and nav */
    align-items: center; /* Center items vertically */
    padding: 10px 20px; /* Adjust padding as needed */
    background-color: #fff; /* Background color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
    position: fixed; /* Fix the header to the top */
    top: 0; /* Align to the top of the viewport */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    z-index: 1000; /* Make sure it stays on top of other content */
}

.brand-logo {
    height: 50px; /* Adjust as necessary */
}

.header-title {
    flex-grow: 1; /* Allow title to take up remaining space */
    text-align: center; /* Center the title text */
    margin: 0; /* Remove default margin */
    font-size: 24px; /* Adjust font size as needed */
    color: #333; /* Set title color */
}

.nav {
    display: flex;
}

.logout-btn {
    background-color: #ff4d4d; /* Logout button color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.logout-btn:hover {
    background-color: #e63946; /* Darker shade on hover */
}
