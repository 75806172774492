.pagination-container {
    max-width: 800px; /* Limit the width of the container */
    margin: auto; /* Center the container */
    padding: 20px;
}

h2 {
    text-align: center; /* Center the heading */
    margin-bottom: 20px; /* Add space below the heading */
    font-size: 24px; /* Increase font size for the heading */
    color: #333; /* Heading color */
}

.items-list {
    list-style-type: none; /* Remove bullet points from list */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}

.item {
    margin-bottom: 10px; /* Add space between items */
}

.card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    display: flex;
    justify-content: space-between; /* Space between content and buttons */
    align-items: flex-start; /* Align items to the start */
}

.card-content {
    flex-grow: 1; /* Allow the content to grow */
}

.card-content h3 {
    margin: 0; /* Remove margin for the title */
    font-size: 18px; /* Set title font size */
}

.card-content p {
    margin: 5px 0; /* Add vertical spacing for paragraphs */
    font-size: 14px; /* Set paragraph font size */
}

.action-buttons {
    display: flex;
    align-items: center; /* Center the action buttons vertically */
}

.delete-label {
    cursor: pointer; /* Change cursor to pointer for delete label */
}

.pagination-controls {
    display: flex;
    justify-content: center; /* Center the pagination controls */
    gap: 10px; /* Space between pagination buttons */
    margin-top: 20px; /* Space above pagination controls */
}

.pagination-controls button {
    padding: 10px 15px; /* Add padding for buttons */
    border: none; /* Remove default border */
    border-radius: 5px; /* Round button corners */
    background-color: #007bff; /* Button color */
    color: white; /* Text color */
    cursor: pointer; /* Change cursor to pointer */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.pagination-controls button:hover {
    background-color: #0056b3; /* Darker color on hover */
}

.pagination-controls .active {
    background-color: #0056b3; /* Active button color */
    font-weight: bold; /* Make active button bold */
}


.loader {
    text-align: center; /* Center the loader */
    font-size: 18px; /* Loader text size */
    margin-top: 20px; /* Add space above the loader */
}
