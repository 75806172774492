.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6); /* Background overlay */
    z-index: 1000;
    overflow-y: auto; /* Enable scroll for the container itself */
}

/* Modal Content */
.modal-content {
    width: 600px; /* Fixed width */
    max-height: 80vh; /* Ensure modal does not exceed 80% of the viewport height */
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    overflow-y: auto; /* Allow vertical scrolling inside the modal if content overflows */
    position: relative;
}

/* .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 12px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
    position: relative;
} */

.close-btn {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: background-color 0.3s ease;
}

.close-btn:hover {
    background-color: #e60000;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .modal-content {
        width: 90%; /* Adapt modal width to smaller screens */
        max-height: 80vh;
    }

    .modal-header {
        font-size: 18px;
    }

    .modal-form button {
        font-size: 14px;
        padding: 10px;
    }

    .modal-form input[type="text"],
    .modal-form input[type="time"],
    .modal-form select {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .modal-content {
        width: 95%; /* Further reduce width on mobile screens */
        max-height: 85vh; /* Increase max height on smaller screens */
    }

    .modal-header {
        font-size: 16px;
    }

    .modal-form input[type="text"],
    .modal-form input[type="time"],
    .modal-form select {
        font-size: 12px;
        padding: 8px;
    }

    .modal-form button {
        font-size: 12px;
        padding: 8px;
    }
}