/* Main Container */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f9; /* Light grey background */
  }
  
  /* Login Box */
  .login-box {
    background: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow */
    width: 350px;
    text-align: center;
  }
  
  /* Logo Placeholder */
  .logo-placeholder img {
    width: 100px; /* Size of the logo placeholder */
    height: auto;
    margin-bottom: 20px;
  }
  
  /* Form Title */
  .login-box h2 {
    font-family: 'Arial', sans-serif;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Form Group Styling */
  .form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  /* Input Fields */
  .input-field {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
  }
  
  /* Buttons */
  .request-button,
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff; /* Blue button */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    font-family: 'Arial', sans-serif;
  }
  
  .request-button:hover,
  .submit-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  /* Error Message */
  .error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
  }
  