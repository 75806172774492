/* General Styles */
.schedule-form {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

input[type="text"],
input[type="time"],
input[type="password"],
select {
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
}

input[type="text"]:focus,
input[type="time"]:focus,
input[type="password"]:focus,
select:focus {
    border-color: #007bff;
    outline: none;
}

button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

/* Error Message */
.error-message {
    color: #e74c3c;
    font-size: 14px;
    margin-top: 8px;
}

/* Calendar Styles */
.calendar-wrapper {
    margin-top: 20px;
}

.calendar {
    width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
    .schedule-form {
        padding: 15px;
    }

    input[type="text"],
    input[type="time"],
    input[type="password"],
    select {
        font-size: 14px;
        padding: 8px;
    }

    button {
        font-size: 14px;
        padding: 10px 15px;
    }
}

@media (max-width: 480px) {
    .schedule-form {
        padding: 10px;
    }

    input[type="text"],
    input[type="time"],
    input[type="password"],
    select {
        font-size: 12px;
        padding: 6px;
    }

    button {
        font-size: 12px;
        padding: 8px 10px;
    }

    label {
        font-size: 14px;
    }
}
